<template>
  <div class="preview-survey">
    <template v-if="!error && page">
      <section class="section">
        <div v-for="question in questions" :key="question.qid" class="mb-4">
          <div class="columns is-centered is-vcentered">
            <component
                :is="`Q${question.type}`"
                :question="question"
                :lang="lang"
                :value="changes"
                @input="onChange"
                :show-question-code="showQuestionCode"
            ></component>
          </div>
        </div>
      </section>
    </template>
    <ErrorMessage :error="error"></ErrorMessage>
  </div>
</template>

<script>
import { getSurveyPage } from '@/api/response'
import { QuestionTypeComponentsMixin } from '@/mixins/question-type-components'
import { checkConditions } from '@/utils/question'
import { ErrorHandlerMixin } from '@/mixins/error-handler'
import { LocaleSwitchingMixin } from '@/mixins/locale-switching'
import { PreviewMixin } from '@/mixins/preview'

export default {
  name: 'PreviewPage',
  mixins: [
    QuestionTypeComponentsMixin,
    ErrorHandlerMixin,
    LocaleSwitchingMixin,
    PreviewMixin,
  ],
  data () {
    return {
      page: null,
    }
  },
  computed: {
    questions () {
      return this.page?.questions.filter(question => checkConditions(question?.conditions, this.changes))
    }
  },
  methods: {
    async init () {
      this.error = null
      const loadingComponent = this.$buefy.loading.open()
      try {
        const { params: { surveyCode, pageCode } } = this.$route
        if (surveyCode && pageCode) {
          await this.getSurvey()
          const { data: { data: page } } = await getSurveyPage(surveyCode, pageCode)
          this.page = page
        }
      } catch (error) {
        this.errorHandler(error)
      } finally {
        loadingComponent.close()
      }
    },
  },
  created () {
    this.init()
  }
}
</script>
